/* .hover:hover {
    background-color: #2f97ff;
}

table{
    overflow-x: scroll;
}

th[draggable] {
    cursor: move;
}

th[draggable]:hover {
    background-color: #f0f0f0;
} */

/* master list sub nav bar start */

.sub-navbar {
    width: fit-content;
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    background-color: #fbfcfe;
    border-bottom: 1px solid #ddd;
}


.sub-nav-item {
    padding: 10px;
    text-decoration: none;
    color: #333;
    border-bottom: 2px solid transparent;
}

.sub-nav-item:hover {
    background-color: #f0f4f8;
}

.sub-nav-item.active {
    background-color: #dde7ee;
    color: #32383e;
    border-bottom: 2px solid #32383e;
}

/* master list sub nav bar end */



/* .tile_hover:hover {
    background-color: #3a4664;
    color: #fff;
} */



.tab-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    gap: 10px;
}

@media (max-width: 768px) {
    .tab-list {
        justify-content: center;
    }
}


input[type=checkbox] {
    zoom: 1.2;
}